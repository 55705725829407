import { useEffect, useState } from 'react';

import CategoryTrackingPixel from './CategoryTrackingPixel/index.client';
import PriceTrackingPixel from './PriceTrackingPixel/index.client';
import { useMarketingContext } from '@/contexts/Marketing/MarketingContext';

const RubiconMagnite = ({ categoryId, price }) => {
  // TODO: we need to rerender this one if the category or the price changed
  const { isDidomiReady } = useMarketingContext();
  const [render, setRender] = useState(false);

  useEffect(() => {
    if (isDidomiReady && window.Didomi.getUserStatusForVendor(52)) {
      setRender(true);
    }
  }, [isDidomiReady]);

  if (!render) {
    return null;
  }

  return (
    <>
      <CategoryTrackingPixel categoryId={categoryId} />
      <PriceTrackingPixel price={price} />
    </>
  );
};

export default RubiconMagnite;
