type PromoConfigType = {
  CAMPAIGN: 'black_weekend' | 'job_promotion_ai';
  START_DATE: string;
  END_DATE: string;
  DISCOUNT_PERCENTAGE: string;
  DISCOUNT_TEXT: string;
  DISCOUNT_SUBTEXT?: string;
  DISPLAY_SUBDOMAINS: string[];
};

const PROMOTION_CONFIG: PromoConfigType = {
  CAMPAIGN: 'black_weekend',
  START_DATE: '2025-01-10T00:00:00',
  END_DATE: '2025-02-11T23:59:59',
  DISCOUNT_PERCENTAGE: '-50',
  DISCOUNT_TEXT: 'Február 10 és 11-én 50% kedvezmény Kirakat kiemelésre! Ne maradj le!',
  DISCOUNT_SUBTEXT: undefined,
  DISPLAY_SUBDOMAINS: [],
};

export default PROMOTION_CONFIG;
